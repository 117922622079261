import React from "react";
//import "./Overview.css";
import {GoOverview, GoProjects, GoAbout, GoContact, GoAcademics} from './Navigation';

export const Academics = (): JSX.Element => {
    return (
        <div id="Academics" className="academics">
            <link rel="stylesheet" type="text/html" href="Academics.css"/>
            <div className="div">
                <div className="element">
                    <div className="text-wrapper">Mathematica</div>
                    <p className="p">
                        I regularly use Mathematica throughout a vast array of projects, school work, research, and
                        recreationally,
                        with over 600 files over the past three years alone.
                    </p>
                </div>
                <div className="overlap">
                    <div className="element-2">
                        <div className="overlap-group">
                            <p className="i-have-volunteered">
                                I have volunteered twice on behalf of programming competitions at UCF. The first,
                                helping oversee a
                                first-pass entrance competition for the programming team. The second, on behalf of the
                                programming
                                team’s High School outreach competition, overseeing contestants for the duration of the
                                competition. In
                                both cases this involved: <br/>
                                • General duties regarding ensuring competition guidelines, aiding the contestants,
                                etc. <br/>
                                • Ensuring the correct functioning of their machines, IDEs, compilers, and both setting up
                                their machines,
                                and fixing any problems which arose.
                            </p>
                            <p className="text-wrapper-2">
                                I have extensively used Latex for effectively all my notes, homework, as well as typing
                                up my
                                professor’s lecture notes, resulting in many hundreds of pages worth of technical writing experience with it.
                            </p>
                            <div className="text-wrapper-4">Minor Awards</div>
                        </div>
                        <p className="the-professor-of-my">
              <span className="span">
                •  The professor of my Cryptography class made delightful challenges out of some homework assignments, by
                which the cipher would give instructions on the location of a prize. I won three out of the four prizes.{" "}
                  <br/>
              </span>
                            <span className="text-wrapper-5">
                •  I also received a departmental award for my performance in Physics with Calculus I and II.{" "}
              </span>
                        </p>
                        <div className="text-wrapper-6">Volunteering</div>
                    </div>
                    <div className="overlap-wrapper">
                        <div className="overlap-2">
                            <div className="overlap-3">
                                <p className="studing-algebraic">
                                    Studying Algebraic Graph Theory for my Honors Research on the Tutte and chromatic
                                    polynomial of the
                                    hypercube graph. Discovered several recurrences related recurrence relations, as
                                    well as surveyed
                                    modern graph techniques. <br/>
                                    •  Thesis defense projected late summer.
                                </p>
                            </div>
                            <div className="overlap-4">
                                <p className="i-was-a-teaching">
                                    I was a teaching assistant for two semesters of Discrete Mathematics (COT 3100)
                                    <br/>
                                    My responsibilities included, <br/>
                                    •  Grading homework and exams.
                                    <br/>
                                    • Holding office hours as well as lecturing weekly labs.
                                </p>
                            </div>
                            <div className="text-wrapper-8">Honors Research & TA Work</div>
                        </div>
                    </div>
                </div>
                <div className="element-3">
                    <p className="algebra-i-MAS">
                        1. Algebra I - (MAS 5311)
              <br/>
                        2. Algebra II{" "}
              - (MAS 6312) In Progress
              <br/>
              These two classes have been a modern approach to abstract algebra, fully sufficient to read algebraic
              literature up to the current decade.
              <br/>
                        3. Introduction to Mathematical Logic - (MHF 5937)
              <br/>
              A special topics course, effectively an introduction to Model Theory, <br/>
              4. Compiler Construction
              <br/>
                        Design and optimization of Compilers. Project-based course.
              <br/>
                        5. Design and Analysis of Algorithms{" "} - (COT 5405)
              (In Progress)
              <br/>
              Largely similar to the material of CS2, but with a greater emphasis on rigor.
              <br/>
              6. Virtual Reality Engineering - (CAP 5115)
              <br/>A project-based course, see Projects for details.
              <br/>
              7. Special Functions
              <br/>
              An survey of various function and polynomials, such as Bessel, Gamma, Chebyshev, Hermite, and
              Hypergeometric. <br/>
              <br/>
              <br/>
                    </p>
                    <p className="combinatorics">
                        1. Combinatorics
                        <br/>
                        2. Computer Architecture
                        <br/>A deeper look at the cache, optimization, branch-prediction. <br/>
                        3. Cryptography
                        <br/>
                        Some historical ciphers. Diffie-Hellman, AES/DES, basic Quantum Cryptography, Elliptic-Curve
                        Cryptography.
                        <br/>
                        4. Programming Languages <br/>A survey of various languages and paradigms. I took this primarily as
                        a
                        prerequisite for Compiler Construction.
                    </p>
                    <div className="text-wrapper-11">Electives</div>
                    <div className="text-wrapper-12">Undergraduate Electives</div>
                    <img className="vector" alt="Vector" src="/img/vector-1.svg"/>
                    <div className="text-wrapper-13">Graduate Electives</div>
                </div>
                <div className="my-academics-wrapper">
                    <p className="my-academics">
                        My academics emphasize both CS and Math graduate electives, Honors research, as well as
                        volunteer and TA
                        work.
                        <br/>
                        <br/>
                        I am intending to submit applications by the end of this Fall to start a graduate school program for
                        Fall 2025
                        semester.
                    </p>
                </div>
                <div className="academics navigation-3">
                    <div className="academics nav-academics-3">
                        <div className="academics nav-academics-text-3">Academics</div>
                    </div>
                    <div onClick={GoProjects} className="academics nav-projects-3">
                        <div className="academics nav-projects-text-3">Projects</div>
                    </div>
                    <div onClick={GoAbout} className="academics nav-about-3">
                        <div className="academics nav-about-text-3">About Me</div>
                    </div>
                    <div onClick={GoOverview} className="academics nav-overview-3">
                        <div className="academics nav-overview-text-3">Overview</div>
                    </div>
                    <div onClick={GoContact} className="academics nav-contact-3">
                        <div className="academics nav-contact-text-3">Contact</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Academics;