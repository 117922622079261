import React from "react";
import {GoOverview, GoProjects, GoAbout, GoContact, GoAcademics} from './Navigation';

export const Contact = (): JSX.Element => {
    return (
        <div className="contact">
            <div className="overlap-group-wrapper">
                <div className="overlap-group">
                    <img className="frame" alt="Frame" src="/img/frame-1.svg" />
                    <p className="distinction">
                        <br />
                        Email:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;jacob.noel@ucf.edu
                        <br />
                        <br />
                        Phone:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;561-516-2390
                        <br />
                        <br />
                        Address:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 449 Alafaya Woods Blvd, Apt. G, Oviedo FL, 32765
                    </p>
                    <div className="text-wrapper">Contact Information</div>
                    <div className="contact navigation-5">
                        <div onClick={GoAcademics} className="contact nav-academics-5">
                            <div className="contact nav-academics-text-5">Academics</div>
                        </div>
                        <div onClick={GoProjects} className="contact nav-projects-5">
                            <div className="contact nav-projects-text-5">Projects</div>
                        </div>
                        <div onClick={GoAbout} className="contact nav-about-5">
                            <div className="contact nav-about-text-5">About Me</div>
                        </div>
                        <div onClick={GoOverview} className="contact nav-overview-5">
                            <div className="contact nav-overview-text-5">Overview</div>
                        </div>
                        <div className="contact nav-contact-5">
                            <div className="contact nav-contact-text-5">Contact</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;