import React from "react";
import "./Overview.css";
import about1 from './static/about1.png'
import about2 from './static/about2.png'
import about3 from './static/about3.png'
import about4 from './static/about4.png'
import about5 from './static/about5.png'
import about6 from './static/about6.png'
import about7 from './static/about7.png'
import about8 from './static/about8.png'
import {GoOverview, GoProjects, GoAbout, GoContact, GoAcademics} from './Navigation';

export const About = (): JSX.Element => {
    return (
        <div className="about-me">
            <div className="div">
                <div className="about navigation-4">
                    <div onClick={GoAcademics} className="about nav-academics-4">
                        <div className="about nav-academics-text-4">Academics</div>
                    </div>
                    <div onClick={GoProjects} className="about nav-projects-4">
                        <div className="about nav-projects-text-4">Projects</div>
                    </div>
                    <div className="about nav-about-4">
                        <div className="about nav-about-text-4">About Me</div>
                    </div>
                    <div onClick={GoOverview} className="about nav-overview-4">
                        <div className="about nav-overview-text-4">Overview</div>
                    </div>
                    <div onClick={GoContact} className="about nav-contact-4">
                        <div className="about nav-contact-text-4">Contact</div>
                    </div>
                </div>
                <div className="overlap-group">
                    <div className="main-2">
                        <img
                            className="img"
                            alt="Img"
                            src={about7}
                        />
                        <img
                            className="img-2"
                            alt="Img"
                            src={about4}
                        />
                        <img className="img-3" alt="Img" src={about2}/>
                        <img className="screenshot" alt="Screenshot" src={about1}/>
                    </div>
                </div>
                <img className="screenshot-2" alt="Screenshot" src={about6}/>
                <div className="main-3">
                    <div className="text-wrapper-7">Gremlin-like creature</div>
                    <img className="zcqvurvnza" alt="Zcqvurvnza" src={about8}/>
                    <img className="IMG" alt="Img" src={about3}/>
                </div>
                <p className="p">Currently, I live in Florida with my husband Jon, and our little gremlin (below).</p>
                <p className="my-hobbies-include">
                    My hobbies include:
                    <br/>
                    <br/>
                    • Drawing and painting
                    <br/>
                    • Personal CS projects
                    <br/>
                    • Recreational mathematics (Project Euler)
                    <br/>
                    • Proof formalization (in Lean 4)
                </p>
                <p className="my-current-favorite">
                    My current favorite areas of study:
                    <br/>
                    • Algebraic Graph Theory <br/>
                    • Model Theory &amp; Formal Logic <br/>
                    • Modern Algebra
                    <br/>
                    <br/>I also like to play piano &amp; brass instruments, as well as write music from time to time.
                </p>
                <img className="screenshot-3" alt="Screenshot" src={about5}/>
                <p className="text-wrapper-8">
                    I’m currently in the top 0.5% of Project Eulerians and hope to complete all of these someday.
                </p>
            </div>
        </div>
    );
};
export default About;