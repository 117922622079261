import React from "react";
import {GoOverview, GoProjects, GoAbout, GoContact, GoAcademics} from './Navigation';
import about7 from "./static/about7.png";
import line from './static/vector-1.svg'

export const Overview = (): JSX.Element => {
    return (
        <div id="Overview" className="overview">
            <link rel="preconnect" href="https://fonts.googleapis.com"/>
            <link
                href="https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC:ital,wght@0,100;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,300;1,400;1,500;1,700;1,800;1,900&display=swap"
                rel="stylesheet"/>
            <link rel="stylesheet" type="text/html" href="Overview.css"/>
            <div className="overview body-wrapper">
                <div className="overview body">
                    <div className="overview of-engineering">of Engineering &amp; Research</div>
                    <div className="overview text-wrapper">Portfolio</div>
                    <div className="overview overlap-group">
                        <p className="overview distinction">
                            Mathematics B.S. &amp; Computer Science B.S. student at the University of Central
                            Florida. <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Graduation, August 2024. Honors
                            Distinction.
                        </p>
                        <div className="overview name">Jacob Noel,</div>
                    </div>
                    <div className="overview navigation-1">
                        <div onClick={GoAcademics} className="overview nav-academics-1">
                            <div className="overview nav-academics-text-1">Academics</div>
                        </div>
                        <div onClick={GoProjects} className="overview nav-projects-1">
                            <div className="overview nav-projects-text-1">Projects</div>
                        </div>
                        <div onClick={GoAbout} className="overview nav-about-1">
                            <div className="overview nav-about-text-1">About Me</div>
                        </div>
                        <div className="overview nav-overview-1">
                            <div className="overview nav-overview-text-1">Overview</div>
                        </div>
                        <div onClick={GoContact} className="overview nav-contact-1">
                            <div className="overview nav-contact-text-1">Contact</div>
                        </div>
                    </div>
                </div>
            </div>
            <img
                className="overview l1"
                src={line}
            />
            <img
                className="overview l2"
                src={line}
            />
        </div>
    );
};

export default Overview;