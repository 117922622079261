import React from "react";
import ITSEC from './static/itsec.png'
import diagram1 from './static/diagram-1.png'
import diagram2 from './static/diagram-2.png'
import vr from './static/vr.png'
import {GoOverview, GoProjects, GoAbout, GoContact, GoAcademics} from './Navigation';

function detectMob() {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];

    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
}

function Projects(){
    if (detectMob()){
        return Projects_Mobile()
    } else {
        return Projects_Desktop()
    }
}

function Projects_Mobile() {
    return (
        <div id="Projects" className="projects">
            <link rel="stylesheet" type="text/html" href="Projects.css"/>
            <div className="div">
                <div className="in-progress">
                    <p className="i-am-currently-in">
                        I am currently in the progress of completing two more projects:
                        <br/>• A transpiler for a subset of C into Futhark, a language with a heavy emphasis on
                        parallelism and
                        GPU-acceleration.
                        <br/>• A highly-optimized Tutte and chromatic polynomial (graph invariant) solver in C++.
                    </p>
                    <div className="text-wrapper">In Progress</div>
                </div>
                <div className="overlap">
                    <div className="cryptography">
                        <div className="overlap-group">
                            <p className="p">
                                On the right is my implementation breaking a 246 character Playfair in ~7 seconds. (Note
                                that ‘Q’ was
                                our repetition character.)
                            </p>
                            <p className="this-class-included">
                                This class included some of the traditional ciphers capable of being broken by hand, as
                                well as more
                                modern DES, key-exchange, etc. <br/>
                                <br/>
                                Two Playfair ciphers were assigned to the class. The first I broke by hand, as intended.
                                Out of interest
                                and variety, for the second I chose to implement a simulated annealing implementation as
                                outlined in
                                Cowan (2008) in C++ with my slight variation that dynamic annealing be used as opposed
                                to static.
                            </p>
                            <div className="text-wrapper-2">Cryptography</div>
                            <div className="terminal">
                                <video loop width="100%">
                                    <source src={require('./static/25.webm')} type="video/mp4"/>
                                </video>
                            </div>
                        </div>
                    </div>
                    <div className="personal-projects">
                        <div className="overlap-2">
                            <div className="overlap-3">
                                <div className="overlap-4">
                                    <p className="text-wrapper-3">
                                        I enjoy shader design and GPU-side visual effects, often involving particle
                                        systems, splines, and
                                        mesh generation.
                                    </p>
                                    <div className="element">
                                        <video loop width="260%">
                                            <source src={require('./static/12.webm')} type="video/webm"/>
                                        </video>
                                    </div>
                                </div>
                                <div className="overlap-5">
                                    <div className="element-2">
                                        <video loop width="100%">
                                            <source src={require('./static/13.webm')} type="video/webm"/>
                                        </video>
                                    </div>
                                    <p className="text-wrapper-4">
                                        This, for example, is an interactive beating-heart, with a fluid dynamic
                                        particle system, visually
                                        inspired by the cuttlefish, which I made for a Valentine-themed event for some
                                        peers.
                                    </p>
                                </div>
                            </div>
                            <div className="element-3">
                                <video loop width="100%">
                                    <source src={require('./static/16.webm')} type="video/webm"/>
                                </video>
                            </div>
                        </div>
                        <div className="main">
                            <div className="element-4">
                                <video loop width="100%">
                                    <source src={require('./static/21.webm')} type="video/webm"/>
                                </video>
                            </div>
                            <div className="overlap-group-2">
                                <div className="element-5">
                                    <video loop width="100%">
                                        <source src={require('./static/23.webm')} type="video/webm"/>
                                    </video>
                                </div>
                                <p className="text-wrapper-5">
                                    Developed a pure CPU-based real-time terminal display in C++ which required the use
                                    of intrinsics for
                                    optimization.
                                </p>
                            </div>
                        </div>
                        <div className="text-wrapper-6">Personal Projects</div>
                    </div>
                </div>
                <div className="overlap-6">
                    <div className="VR-engineering">
                        <div className="overlap-7">
                            <div className="main-2"/>
                            <p className="above-i-also">
                                Above, I also implemented interaction with a pump and handling various soft-body
                                simulation assets.
                                <br/>
                                <br/>
                                On the left, I wrote a hidden menu that varies opacity according to the angle of
                                observation.
                                Additionally, I wrote a way to place and modify the sun in scene, as well as a
                                bi-colored sphere to
                                indicate placement.
                            </p>
                            <p className="text-wrapper-7">
                                On the right is demonstrated interaction with the UI, by changing the backgrounds as
                                well as varying the
                                sun’s parameters.
                            </p>
                            <div className="element-6">
                                <video loop width="100%">
                                    <source src={require('./static/10fix.webm')} type="video/webm"/>
                                </video>
                            </div>
                            <div className="element-7">
                                <video loop width="100%">
                                    <source src={require('./static/11fix.webm')} type="video/webm"/>
                                </video>
                            </div>
                            <div className="element-8">
                                <video loop width="100%">
                                    <source src={require('./static/8fix.webm')} type="video/webm"/>
                                </video>
                            </div>
                            <div className="element-9">
                                <video loop width="100%">
                                    <source src={require('./static/7fix.webm')} type="video/webm"/>
                                </video>
                            </div>
                        </div>
                        <div className="overlap-8">
                            <div className="overlap-9">
                                <div className="main-3">
                                    <div className="element-10">
                                        <video loop width="100%">
                                            <source src={require('./static/3.webm')} type="video/webm"/>
                                        </video>
                                    </div>
                                    <div className="element-11">
                                        <video loop width="100%">
                                            <source src={require('./static/4.webm')} type="video/webm"/>
                                        </video>
                                    </div>
                                    <div className="element-12">
                                        <video loop width="100%">
                                            <source src={require('./static/5.webm')} type="video/webm"/>
                                        </video>
                                    </div>
                                    <div className="element-13">
                                        <video loop width="100%">
                                            <source src={require('./static/6.webm')} type="video/mp4"/>
                                        </video>
                                    </div>
                                    <div className="overlap-group-3">
                                        <p className="text-wrapper-9">
                                            For scene design, I wanted to use this as an opportunity to get experience
                                            working with bloom,
                                            baked lighting, emissive materials, and various visual effects.
                                            <br/>
                                            <br/>
                                            For interaction, I wanted to implement something similar to the Gravity
                                            glove from Half-Life: Alyx. The rocks are launched in an arc that is guided
                                            to the player’s hand.
                                        </p>
                                        <img className="vector" alt="Vector" src="."/>
                                    </div>
                                </div>
                                <img className="screenshot" alt="Screenshot"
                                     src={vr}/>
                            </div>
                            <p className="my-course-on-VR">
                                My course on VR engineering required the creation of a handful of small set pieces, used
                                to demonstrate
                                understanding of aspects of VR development. The general themes were:
                                <br/>
                                • Scene Design <br/>
                                • Interaction
                                <br/>
                                • UI, etc.
                                <br/>
                                • Maintaining performance throughout.
                            </p>
                        </div>
                        <div className="text-wrapper-10">Virtual Reality Engineering</div>
                    </div>
                    <div className="senior-design">
                        <p className="i-also-developed">
                            I also developed (largely through use of shaders):
                            <br/>• A minimap with dynamic axes and lines during zooming and translation.
                            <br/>• A horizontal as well as radial compass with options for cardinals, degrees, and mils.
                            <br/>• User defined lines and indicators, as well as paths for display during playback
                            recording.
                        </p>
                        <p className="recording-and">
                            • Recording and playback with seeking as well as serialization and exporting/importing.
                            <br/>
                            • Conversion between ECEF, ENU, and Geodetic coordinates with respect to a terrain defined
                            reference point.
                            <br/>
                            • Multiple spectrogram visual modes.
                        </p>
                        <div className="overlap-10">
                            <div className="overlap-11">
                                <p className="i-also-designed">
                                    I also designed technical diagrams to help give clear documentation to any future
                                    teams who wish to
                                    build on this work, as well as helping write and edit an over 120 page technical
                                    write-up.
                                    <br/>
                                    <br/>
                                    Additionally, this project was chosen to go on to another iteration of Senior Design
                                    and I have been
                                    volunteering as an advisor to the new team.
                                </p>
                                <img className="diagram" alt="Diagram" src={diagram2}/>
                            </div>
                            <img className="img" alt="Diagram" src={diagram1}/>
                        </div>
                        <p className="for-this-i-developed">
                            For this I developed a novel signal-baking and rendering pipeline for displaying
                            high-frequency sources in
                            real-time.
                            <br/>
                            This required the use of:
                            <br/>
                            • Compute and Rendering shaders
                            <br/>
                            • NumPy (for baking) and SciPy (for modulation)
                            <br/>
                            • Multiple propagation methods. <br/>• A debug display mode for instructors to visualize signal
                            location as
                            light sources.
                            <br/>
                            • As well as developing all of this in Unity with C#, HLSL, and Python used for baking.
                        </p>
                        <p className="for-my-bachelors-in">
                            For my bachelor's in Computer Science a group based Senior Design project is required.
                            <br/>
                            <br/>I worked on a novel real-time field spectrum analysis training simulator.
                            <br/>
                            <br/>I went on to be an exhibitor of the project at I/ITSEC, demoing to Lt. Gen. Maria
                            Barrett and Steven
                            Rehn of the U.S. Army Cyber Command
                        </p>
                        <div className="videos">
                            <div className="recording-playback">Recording &amp; Playback</div>
                            <div className="text-wrapper-11">Signal Propagation</div>
                            <div className="text-wrapper-12">Signal Visualization Mode</div>
                            <div className="element-14">
                                <video loop width="100%">
                                    <source src={require('./static/18.webm')} type="video/mp4"/>
                                </video>
                            </div>
                            <div className="element-15">
                                <video loop width="100%">
                                    <source src={require('./static/19.webm')} type="video/mp4"/>
                                </video>
                            </div>
                            <div className="element-16">
                                <video loop width="100%">
                                    <source src={require('./static/2.webm')} type="video/mp4"/>
                                </video>
                            </div>
                        </div>
                        <div className="videos-2">
                            <div className="text-wrapper-13">Minimap Functionality</div>
                            <div className="text-wrapper-14">Spectrogram Functionality</div>
                            <img className="ITSEC" alt="Itsec" src={ITSEC}/>
                            <div className="spectrogram-video">
                                <video loop width="100%">
                                    <source src={require('./static/1.webm')} type="video/mp4"/>
                                </video>
                            </div>
                            <div className="minimap-video">
                                <video loop width="100%">
                                    <source src={require('./static/17.webm')} type="video/webm"/>
                                </video>
                            </div>
                        </div>
                        <div className="text-wrapper-15">Senior Design</div>
                    </div>
                </div>
                <div className="navigation-2">
                    <div onClick={GoAcademics} className="nav-academics-2">
                        <div className="nav-academics-text-2">Academics</div>
                    </div>
                    <div className="nav-projects-2">
                        <div className="nav-projects-text-2">Projects</div>
                    </div>
                    <div onClick={GoAbout} className="nav-about-2">
                        <div className="nav-about-text-2">About Me</div>
                    </div>
                    <div onClick={GoOverview} className="nav-overview-2">
                        <div className="nav-overview-text-2">Overview</div>
                    </div>
                    <div onClick={GoContact} className="nav-contact-2">
                        <div className="nav-contact-text-2">Contact</div>
                    </div>
                </div>
            </div>
        </div>
    );
};


function Projects_Desktop() {
    return (
        <div id="Projects" className="projects">
            <link rel="stylesheet" type="text/html" href="Projects.css"/>
            <div className="div">
                <div className="in-progress">
                    <p className="i-am-currently-in">
                        I am currently in the progress of completing two more projects:
                        <br/>• A transpiler for a subset of C into Futhark, a language with a heavy emphasis on
                        parallelism and
                        GPU-acceleration.
                        <br/>• A highly-optimized Tutte and chromatic polynomial (graph invariant) solver in C++.
                    </p>
                    <div className="text-wrapper">In Progress</div>
                </div>
                <div className="overlap">
                    <div className="cryptography">
                        <div className="overlap-group">
                            <p className="p">
                                On the right is my implementation breaking a 246 character Playfair in ~7 seconds. (Note
                                that ‘Q’ was
                                our repetition character.)
                            </p>
                            <p className="this-class-included">
                                This class included some of the traditional ciphers capable of being broken by hand, as
                                well as more
                                modern DES, key-exchange, etc. <br/>
                                <br/>
                                Two Playfair ciphers were assigned to the class. The first I broke by hand, as intended.
                                Out of interest
                                and variety, for the second I chose to implement a simulated annealing implementation as
                                outlined in
                                Cowan (2008) in C++ with my slight variation that dynamic annealing be used as opposed
                                to static.
                            </p>
                            <div className="text-wrapper-2">Cryptography</div>
                            <div className="terminal">
                                <video loop autoPlay width="100%">
                                    <source src={require('./static/25.webm')} type="video/mp4"/>
                                </video>
                            </div>
                        </div>
                    </div>
                    <div className="personal-projects">
                        <div className="overlap-2">
                            <div className="overlap-3">
                                <div className="overlap-4">
                                    <p className="text-wrapper-3">
                                        I enjoy shader design and GPU-side visual effects, often involving particle
                                        systems, splines, and
                                        mesh generation.
                                    </p>
                                    <div className="element">
                                        <video loop autoPlay width="260%">
                                            <source src={require('./static/12.webm')} type="video/webm"/>
                                        </video>
                                    </div>
                                </div>
                                <div className="overlap-5">
                                    <div className="element-2">
                                        <video loop autoPlay width="100%">
                                            <source src={require('./static/13.webm')} type="video/webm"/>
                                        </video>
                                    </div>
                                    <p className="text-wrapper-4">
                                        This, for example, is an interactive beating-heart, with a fluid dynamic
                                        particle system, visually
                                        inspired by the cuttlefish, which I made for a Valentine-themed event for some
                                        peers.
                                    </p>
                                </div>
                            </div>
                            <div className="element-3">
                                <video loop autoPlay width="100%">
                                    <source src={require('./static/16.webm')} type="video/webm"/>
                                </video>
                            </div>
                        </div>
                        <div className="main">
                            <div className="element-4">
                                <video loop autoPlay width="100%">
                                    <source src={require('./static/21.webm')} type="video/webm"/>
                                </video>
                            </div>
                            <div className="overlap-group-2">
                                <div className="element-5">
                                    <video loop autoPlay width="100%">
                                        <source src={require('./static/23.webm')} type="video/webm"/>
                                    </video>
                                </div>
                                <p className="text-wrapper-5">
                                    Developed a pure CPU-based real-time terminal display in C++ which required the use
                                    of intrinsics for
                                    optimization.
                                </p>
                            </div>
                        </div>
                        <div className="text-wrapper-6">Personal Projects</div>
                    </div>
                </div>
                <div className="overlap-6">
                    <div className="VR-engineering">
                        <div className="overlap-7">
                            <div className="main-2"/>
                            <p className="above-i-also">
                                Above, I also implemented interaction with a pump and handling various soft-body
                                simulation assets.
                                <br/>
                                <br/>
                                On the left, I wrote a hidden menu that varies opacity according to the angle of
                                observation.
                                Additionally, I wrote a way to place and modify the sun in scene, as well as a
                                bi-colored sphere to
                                indicate placement.
                            </p>
                            <p className="text-wrapper-7">
                                On the right is demonstrated interaction with the UI, by changing the backgrounds as
                                well as varying the
                                sun’s parameters.
                            </p>
                            <div className="element-6">
                                <video loop autoPlay width="100%">
                                    <source src={require('./static/10fix.webm')} type="video/webm"/>
                                </video>
                            </div>
                            <div className="element-7">
                                <video loop autoPlay width="100%">
                                    <source src={require('./static/11fix.webm')} type="video/webm"/>
                                </video>
                            </div>
                            <div className="element-8">
                                <video loop autoPlay width="100%">
                                    <source src={require('./static/8fix.webm')} type="video/webm"/>
                                </video>
                            </div>
                            <div className="element-9">
                                <video loop autoPlay width="100%">
                                    <source src={require('./static/7fix.webm')} type="video/webm"/>
                                </video>
                            </div>
                        </div>
                        <div className="overlap-8">
                            <div className="overlap-9">
                                <div className="main-3">
                                <div className="element-10">
                                        <video loop autoPlay width="100%">
                                            <source src={require('./static/3.webm')} type="video/webm"/>
                                        </video>
                                    </div>
                                    <div className="element-11">
                                        <video loop autoPlay width="100%">
                                            <source src={require('./static/4.webm')} type="video/webm"/>
                                        </video>
                                    </div>
                                    <div className="element-12">
                                        <video loop autoPlay width="100%">
                                            <source src={require('./static/5.webm')} type="video/webm"/>
                                        </video>
                                    </div>
                                    <div className="element-13">
                                        <video loop autoPlay width="100%">
                                            <source src={require('./static/6.webm')} type="video/mp4"/>
                                        </video>
                                    </div>
                                    <div className="overlap-group-3">
                                        <p className="text-wrapper-9">
                                            For scene design, I wanted to use this as an opportunity to get experience
                                            working with bloom,
                                            baked lighting, emissive materials, and various visual effects.
                                            <br/>
                                            <br/>
                                            For interaction, I wanted to implement something similar to the Gravity
                                            glove from Half-Life: Alyx. The rocks are launched in an arc that is guided
                                            to the player’s hand.
                                        </p>
                                        <img className="vector" alt="Vector" src="."/>
                                    </div>
                                </div>
                                <img className="screenshot" alt="Screenshot"
                                     src={vr}/>
                            </div>
                            <p className="my-course-on-VR">
                                My course on VR engineering required the creation of a handful of small set pieces, used
                                to demonstrate
                                understanding of aspects of VR development. The general themes were:
                                <br/>
                                • Scene Design <br/>
                                • Interaction
                                <br/>
                                • UI, etc.
                                <br/>
                                • Maintaining performance throughout.
                            </p>
                        </div>
                        <div className="text-wrapper-10">Virtual Reality Engineering</div>
                    </div>
                    <div className="senior-design">
                        <p className="i-also-developed">
                            I also developed (largely through use of shaders):
                            <br/>• A minimap with dynamic axes and lines during zooming and translation.
                            <br/>• A horizontal as well as radial compass with options for cardinals, degrees, and mils.
                            <br/>• User defined lines and indicators, as well as paths for display during playback
                            recording.
                        </p>
                        <p className="recording-and">
                            • Recording and playback with seeking as well as serialization and exporting/importing.
                            <br/>
                            • Conversion between ECEF, ENU, and Geodetic coordinates with respect to a terrain defined
                            reference point.
                            <br/>
                            • Multiple spectrogram visual modes.
                        </p>
                        <div className="overlap-10">
                            <div className="overlap-11">
                                <p className="i-also-designed">
                                    I also designed technical diagrams to help give clear documentation to any future
                                    teams who wish to
                                    build on this work, as well as helping write and edit an over 120 page technical
                                    write-up.
                                    <br/>
                                    <br/>
                                    Additionally, this project was chosen to go on to another iteration of Senior Design
                                    and I have been
                                    volunteering as an advisor to the new team.
                                </p>
                                <img className="diagram" alt="Diagram" src={diagram2}/>
                            </div>
                            <img className="img" alt="Diagram" src={diagram1}/>
                        </div>
                        <p className="for-this-i-developed">
                            For this I developed a novel signal-baking and rendering pipeline for displaying
                            high-frequency sources in
                            real-time.
                            <br/>
                            This required the use of:
                            <br/>
                            • Compute and Rendering shaders
                            <br/>
                            • NumPy (for baking) and SciPy (for modulation)
                            <br/>
                            • Multiple propagation methods. <br/>• A debug display mode for instructors to visualize signal
                            location as
                            light sources.
                            <br/>
                            • As well as developing all of this in Unity with C#, HLSL, and Python used for baking.
                        </p>
                        <p className="for-my-bachelors-in">
                            For my bachelor's in Computer Science a group based Senior Design project is required.
                            <br/>
                            <br/>I worked on a novel real-time field spectrum analysis training simulator.
                            <br/>
                            <br/>I went on to be an exhibitor of the project at I/ITSEC, demoing to Lt. Gen. Maria
                            Barrett and Steven
                            Rehn of the U.S. Army Cyber Command
                        </p>
                        <div className="videos">
                            <div className="recording-playback">Recording &amp; Playback</div>
                            <div className="text-wrapper-11">Signal Propagation</div>
                            <div className="text-wrapper-12">Signal Visualization Mode</div>
                            <div className="element-14">
                                <video loop autoPlay width="100%">
                                    <source src={require('./static/18.webm')} type="video/mp4"/>
                                </video>
                            </div>
                            <div className="element-15">
                                <video loop autoPlay width="100%">
                                    <source src={require('./static/19.webm')} type="video/mp4"/>
                                </video>
                            </div>
                            <div className="element-16">
                                <video loop autoPlay width="100%">
                                    <source src={require('./static/2.webm')} type="video/mp4"/>
                                </video>
                            </div>
                        </div>
                        <div className="videos-2">
                            <div className="text-wrapper-13">Minimap Functionality</div>
                            <div className="text-wrapper-14">Spectrogram Functionality</div>
                            <img className="ITSEC" alt="Itsec" src={ITSEC}/>
                            <div className="spectrogram-video">
                                <video loop autoPlay width="100%">
                                    <source src={require('./static/1.webm')} type="video/mp4"/>
                                </video>
                            </div>
                            <div className="minimap-video">
                                <video loop autoPlay width="100%">
                                    <source src={require('./static/17.webm')} type="video/webm"/>
                                </video>
                            </div>
                        </div>
                        <div className="text-wrapper-15">Senior Design</div>
                    </div>
                </div>
                <div className="navigation-2">
                    <div onClick={GoAcademics} className="nav-academics-2">
                        <div className="nav-academics-text-2">Academics</div>
                    </div>
                    <div className="nav-projects-2">
                        <div className="nav-projects-text-2">Projects</div>
                    </div>
                    <div onClick={GoAbout} className="nav-about-2">
                        <div className="nav-about-text-2">About Me</div>
                    </div>
                    <div onClick={GoOverview} className="nav-overview-2">
                        <div className="nav-overview-text-2">Overview</div>
                    </div>
                    <div onClick={GoContact} className="nav-contact-2">
                        <div className="nav-contact-text-2">Contact</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Projects;