import ReactDOM from "react-dom/client";
import React from "react";
import Overview from "./Overview";
import Projects from "./Projects";
import Academics from "./Academics";
import About from "./About";
import Contact from "./Contact";

//import './About.css';
//import './Academics.css';
//import './Contact.css';
//import './Overview.css';
//import './Projects.css';

function changeCSS(cssFile : string) {
    return
    const style = document.createElement('link')
    console.log(style)
    style!.setAttribute('href', cssFile);


    var stylesheet = document.getElementById("style");
    return
}

function GoOverview(){
    const root = ReactDOM.createRoot(
        document.getElementById('root') as HTMLElement
    );

    root.render(
        <React.StrictMode>
            <Overview />
        </React.StrictMode>
    );

    changeCSS("Overview.css")
    console.log("GoOverview")
}

function GoContact(){
    const root = ReactDOM.createRoot(
        document.getElementById('root') as HTMLElement
    );

    root.render(
        <React.StrictMode>
            <Contact />
        </React.StrictMode>
    );

    changeCSS("Contact.css");
    console.log("GoContact")
}

function GoAbout(){
    const root = ReactDOM.createRoot(
        document.getElementById('root') as HTMLElement
    );

    root.render(
        <React.StrictMode>
            <About />
        </React.StrictMode>
    );

    changeCSS("About.css");
    console.log("GoAbout")
}

function GoAcademics(){
    //return
    const root = ReactDOM.createRoot(
        document.getElementById('root') as HTMLElement
    );

    root.render(
        <React.StrictMode>
            <Academics />
        </React.StrictMode>
    );

    changeCSS("Academics.css");
    console.log("GoAcademics")
}

function GoProjects(){


    const root = ReactDOM.createRoot(
        document.getElementById('root') as HTMLElement
    );

    root.render(
        <React.StrictMode>
            <Projects />
        </React.StrictMode>
    );

    changeCSS("Projects.css");
    console.log("GoProjects")
}

export {
    GoOverview,
    GoAbout,
    GoContact,
    GoAcademics,
    GoProjects
};
