import React from "react";
import './About.css';
import './Academics.css';
import './Contact.css';
import './Overview.css';
import './Projects.css';
import { SimplyFade } from 'simply-fades';
import ReactDOM from "react-dom/client";


import Overview from "./Overview";

document.addEventListener('DOMContentLoaded', () => {
  new SimplyFade()
})

export default Overview;
